/**
 * 退款单API
 */

import request from '@/utils/request'

/**
 * 查询卡券订单详情功能
 * @param params
 * @returns {Promise<any>}
 */
export function getCouponOrderDetail(params) {
  return request({
    url: 'seller/trade/orders/seller/cards/sell/info',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 查询卡券退单详情功能
 * @param params
 * @returns {Promise<any>}
 */
export function getRefundCouponOrderDetail(params) {
  return request({
    url: 'seller/trade/orders/seller/cards/refund/sell/info',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 通过卡号段查询卡号功能
 * @param params
 * @returns {Promise<any>}
 */
export function getQueryCardNumber(params) {
  return request({
    url: 'seller/trade/orders/seller/cards/number',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 商家获取退款/货单列表
 * @param params
 * @returns {Promise<any>}
 */
export function getRefundList(params) {
  return request({
    url: 'seller/after-sales/shopRefundsList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 供应商获取退款/货单列表
 * @param params
 * @returns {Promise<any>}
 */
export function getSellerRefundList(params) {
  return request({
    url: 'seller/after-sales/sellerRefundsList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 企业获取退款/货单列表
 * @param params
 * @returns {Promise<any>}
 */
export function enterpriseRefundsList(params) {
  return request({
    url: 'seller/after-sales/enterpriseRefundsList',
    method: 'get',
    loading: false,
    params
  })
}

/**
 * 退款/货 详情
 * @param sn
 * @returns {Promise<any>}
 */
export function getRefundDetails(sn) {
  return request({
    url: `seller/after-sales/refunds/${sn}`,
    method: 'get',
    loading: false
  })
}

/**
 * 卖家审核退款/退货
 * @param sn
 * @param params
 * @returns {Promise<any>}
 */
export function refundAuth(sn, params) {
  return request({
    url: `seller/after-sales/audits/${sn}`,
    method: 'post',
    loading: false,
    data: params
  })
}

/**
 * 卖家执行退款
 * @param sn
 * @param params
 * @returns {Promise<any>}
 */
export function toRefund(sn) {
  return request({
    url: `seller/after-sales/refunds/${sn}`,
    method: 'post',
    loading: false
  })
}

/**
 * 入库操作
 * @param sn
 * @param params
 * @returns {Promise<any>}
 */
export function wareHousing(sn, params) {
  return request({
    url: `seller/after-sales/stock-ins/${sn}`,
    method: 'post',
    loading: false,
    data: params
  })
}

/**
 * 发货
 * @param sn
 * @param params
 * @returns {Promise<any>}
 */
export function sellerShip(sn, params) {
  return request({
    url: `seller/after-sales/sellerShip/${sn}`,
    method: 'post',
    loading: false,
    data: params
  })
}

/**
 * 拒绝发货
 * @param sn
 * @param params
 * @returns {Promise<any>}
 * @constructor
 */
export function sellerShipRefuse(sn, params) {
  return request({
    url: `seller/after-sales/sellerShipRefuse/${sn}`,
    method: 'post',
    loading: false,
    data: params
  })
}

/**
 * 智能套餐卡审核售后
 * @param sn
 * @param params
 */
export function refundAIAuth(sn, params) {
  return request({
    url: `/seller/after-sales/auditsTh/${sn}`,
    method: 'post',
    loading: false,
    data: params
  })
}

/**
 * 智能套餐卡卖家确认收货操作
 */
export function comboShopWareHousing(sn) {
  return request({
    url: `/seller/after-sales/stock-insTh/${sn}`,
    method: 'post',
    loading: false
  })
}

/**
 * 智能套餐卡维权订单获取订单详情
 * @param sn
 */
export function comboShopRefundDetail(sn) {
  return request({
    url: `seller/after-sales/refundsTh/${sn}`,
    method: 'get'
  })
}

/**
 * @id
 */
export function cancelRefundOrder(sn) {
  return request({
    url: `/seller/after-sales/refunds/cancel/${sn}`,
    method: 'post',
    loading: false
  })
}
/**
 * 一件代发商城申请售后
 */
export function refundsApplyDs(params) {
  return request({
    url: `/seller/after-sales/refunds/applyDs`,
    method: 'post',
    loading: false,
    data:params
  })
}
/**
 * 一件代发商城修改售后
 */
export function updaterefundsApplyDs(params) {
  return request({
    url: `/seller/after-sales/refunds/update/${params.id}`,
    method: 'post',
    loading: false,
    data:params
  })
}
/**
 * 供应商后台配置的售后地址
 */
export function refundAddress() {
  return request({
    url: '/seller/after-sales/refundAddress',
    method: 'get'
  })
}
/**
 * 后台配置的售后地址
 */
export function sellerAddress(shop_id) {
  return request({
    url: `/seller/shops/refund/address/${shop_id}`,
    method: 'get'
  })
}
/**
 * 一件代发商城修改售后
 */
export function sendbackRefund(params) {
  return request({
    url: `/seller/after-sales/buyer-send-back/${params.sn}`,
    method: 'post',
    loading: false,
    data:params
  })
}
